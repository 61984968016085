import React, { Component } from "react";
import styled from "styled-components";
import { Bounds } from "google-map-react";
import { connect } from "react-redux";
import {
  useTranslation,
  withTranslation,
  WithTranslation,
} from "react-i18next";
import { differenceInDays, format, startOfToday } from "date-fns";
import _ from "lodash/fp";
import {
  DeviceType,
  FillState,
  SelectableColumns,
  MachineType,
} from "../interfaces/types";
import * as TYPES from "../constants/actionTypes";

import icon_analytics from "../assets/icon-analytics.svg";
import icon_info from "../assets/icon-info.svg";
import icon_route from "../assets/icon-route.svg";
import icon_route_active from "../assets/icon-route-active.svg";
import icon_reset from "../assets/icon-reset.svg";
import icon_edit from "../assets/icon-edit.svg";
import icon_ok from "../assets/icon-status-ok.svg";
import icon_close from "../assets/icon-close-blue.svg";
import { RentalPeriod } from "./RentalPeriod";
import { mapOptions } from "../constants/options";
import { StatusIcon } from "./Common/StatusIcon";
import {
  getPrimaryStatus,
  getStatusMessage,
  hasErrorOrWarning,
} from "../utils/deviceStatus";
import { getLatLng } from "../utils/utils";
import { Formik } from "formik";
import { formFieldCss } from "./Common/FormInput";
import { withoutPropagation } from "../utils/withoutPropagation";
import * as Yup from "yup";
import { getDateFnsLocale, getValidationErrorText } from "../utils/i18nUtils";
import { FillLevel } from "@shared/types";

interface DeviceProps extends DeviceType, WithTranslation {
  // Active columns
  columns: SelectableColumns[];
  onShowRoute(device: DeviceType, visible: boolean): void;
  onEditDevice(device: DeviceType): void;
  onShowAnalytics(device: DeviceType): void;
  onChangeBalesReady(device: DeviceType, newBalesReady: number): void;
  onResetBaleCounter(device: DeviceType): void;
  onShowServiceRequests(device: DeviceType): void;
  onDeviceRowClick(zoom: number, bounds: Bounds, center: number[]): void;
  bounds: Bounds;
  user: { groups: string[]; admin: boolean; language: string | undefined };
}
interface DeviceState {
  showRoute: boolean;
  balesReadyEditOngoing: boolean;
}

interface RowProps {
  background: string;
  clickable: boolean;
}
const Row = styled.tr<RowProps>`
  display: grid;
  grid-template-rows: repeat(4, auto);
  grid-template-columns: auto auto;
  background-color: ${(props) => props.background};
  width: 100%;
  height: 120px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  @media (min-width: 768px) {
    display: table-row;
    grid-template-rows: none;
    grid-template-columns: none;
    height: 100px;
  }
  &:hover {
    cursor: ${(props) => (props.clickable ? "pointer" : "auto")};
    opacity: ${(props) => (props.clickable ? 0.8 : 1.0)};
  }
`;
const Column = styled.td`
  display: block;
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #000000;
  @media (min-width: 768px) {
    display: table-cell;
  }
`;
interface CellProps {
  textColor?: string;
  flexDirection?: string;
}
const Cell = styled.div<CellProps>`
  display: flex;
  flex-direction: ${(props) => props.flexDirection || "row"};
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-left: 10px;
  color: ${(props) => (props.textColor ? props.textColor : "black")};
`;

interface IconToolProps {
  marginLeft?: number;
}
const IconTool = styled.img<IconToolProps>`
  object-fit: contain;
  cursor: pointer;
  outline: none;
  margin-left: ${(props) =>
    props.marginLeft !== undefined ? props.marginLeft : "0"}px;
`;

export const Percent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  text-align: center;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;

  text-align: center;

  color: #1c2020;
`;

export const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  border-radius: 12px;
  background-color: var(--twilight-blue);
  font-family: Roboto;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 3.33;
  letter-spacing: normal;
  color: #fafbfc;
`;

const FillLevelRect = styled.div`
  display: flex;
  align-items: center;
  width: 80px;
  height: 24px;
  border-radius: 12px;
  border: solid 1px #e9eef3;
  background-color: #ffffff;
  @media (min-width: 768px) {
    width: 108px;
  }
`;

const FillLevelPercent = styled.div`
  margin-top: 0.3rem;
`;

const IconShowLocation = styled.img`
  height: 19px;
  width: 18px;
  margin-right: 5px;
`;

interface ShowRouteRectProps {
  background: boolean;
}

const ShowLocationsRect = styled.div<ShowRouteRectProps>`
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 118px;
  height: 28px;
  border-radius: 14px;
  margin-top: 5px;
  background-color: ${(props) =>
    props.background === true ? "var(--col-214269)" : "#ffffff"};
  font-family: Roboto;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: condensed;
  line-height: normal;
  letter-spacing: normal;
  color: ${(props) =>
    props.background === true ? "#ffffff" : "var(--col-214269)"};
`;

interface FillLevelIconProps {
  width: number;
  background: string;
}

const FillLevelIcon = styled.div<FillLevelIconProps>`
  width: ${(props) => props.width}%;
  height: 100%;
  border-radius: 12px;
  background-color: ${(props) => props.background};
  @media (min-width: 768px) {
  }
`;

const FillLevelPercentIcon = styled.div<FillLevelIconProps>`
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: linear-gradient(
    to right,
    ${(props) => props.background} ${(props) => props.width}%,
    #ffffff 0%
  );
  @media (min-width: 768px) {
  }
`;

const BalesReadyInput = styled.input`
  margin-right: 0.25rem;

  ${formFieldCss}

  // Remove arrows (aka spinners) from <input type="number">
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  width: 2rem;
  border-radius: 4px;
  padding: 0.2rem;
  text-align: center;
  font-size: inherit;
  font-family: inherit;
  color: inherit;
`;
const BalesReadyErrorMessage = styled.span`
  font-size: 0.75rem;
  line-height: 1;
  color: var(--df-6940);
`;
const BalesReadySubmitIcon = styled.img.attrs({ src: icon_ok })`
  margin-left: 12px;
  // Shift upwards to align with cancel icon due to internal padding in SVG
  margin-bottom: 4px;
  width: 24px;
  cursor: pointer;
  :hover {
    background-color: #ffffff;
  }
`;
const BalesReadyCancelIcon = styled.img.attrs({ src: icon_close })`
  width: 24px;
  cursor: pointer;
  :hover {
    background-color: #ffffff;
  }
`;
const BalesReadyFormInner = styled.form`
  display: flex;
  align-items: center;
`;
const BalesReadyFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const BalesReadyForm = ({
  balesReady,
  pickupLimit,
  onSubmit,
  onCancel,
}: {
  balesReady: number;
  pickupLimit: number;
  onSubmit: (balesReady: number) => void;
  onCancel: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={{ balesReady }}
      onSubmit={(values) => {
        onSubmit(values.balesReady);
      }}
      validationSchema={Yup.object().shape({
        balesReady: Yup.number().moreThan(-1).required(),
      })}
    >
      {(props) => (
        <BalesReadyFormWrapper>
          <BalesReadyFormInner onSubmit={props.handleSubmit}>
            <BalesReadyInput
              type="number"
              name="balesReady"
              onChange={props.handleChange}
              value={props.values.balesReady}
              onClick={(e) => {
                e.stopPropagation();
              }}
            />{" "}
            / {pickupLimit}
            <BalesReadySubmitIcon
              onClick={withoutPropagation(props.submitForm)}
              title={t("common.save", "Save")}
            />
            <BalesReadyCancelIcon
              onClick={withoutPropagation(onCancel)}
              title={t("common.cancel", "Cancel")}
            />
          </BalesReadyFormInner>
          <BalesReadyErrorMessage>
            {0 < props.submitCount
              ? getValidationErrorText(props.errors.balesReady)
              : ""}
          </BalesReadyErrorMessage>
        </BalesReadyFormWrapper>
      )}
    </Formik>
  );
};

const BalesReadyEditIcon = styled.img.attrs({ src: icon_edit })`
  width: 26px;
  margin-left: 10px;
  cursor: pointer;
  :hover {
    background-color: #ffffff;
  }
`;
const BaleCounterResetIcon = styled.img.attrs({ src: icon_reset })`
  width: 17px;
  cursor: pointer;
  :hover {
    background-color: #ffffff;
  }
`;
const BalesReadyCellContent = (props: {
  balesReady: number;
  pickupLimit: number;
  onEdit: () => void;
  onReset: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <span>
        {props.balesReady ?? 0} / {props.pickupLimit}
      </span>
      <BalesReadyEditIcon
        onClick={withoutPropagation(props.onEdit)}
        title={t("deviceTable.tooltip.changeBalesReady", "Change bales ready")}
      />
      <BaleCounterResetIcon
        onClick={withoutPropagation(props.onReset)}
        title={t("deviceTable.tooltip.resetBaleCounter", "Reset bale counter")}
      />
    </>
  );
};

export function getPredictedFillUp(
  device: DeviceType
): {
  fillUpType: "full" | "notFullUntil";
  fillUpDays: number;
} | null {
  const {
    validatedFullDate: fullDate,
    validatedNotFullUntil: notFullUntil,
  } = device;

  if (
    FillLevel.FULL === device.fillLevel ||
    FillLevel.OVERFULL === device.fillLevel
  ) {
    return null;
  }

  if (fullDate) {
    const daysToFull = differenceInDays(new Date(fullDate), startOfToday());

    if (daysToFull < 2) {
      return { fillUpType: "full", fillUpDays: 1 };
    } else if (daysToFull < 30) {
      return { fillUpType: "full", fillUpDays: daysToFull };
    } else {
      return { fillUpType: "notFullUntil", fillUpDays: 31 };
    }

    // If fullDate == null and notFullUntil != null, the device shouldn't be full in at least a
    // month (same logic as in mobile apps)
  } else if (notFullUntil) {
    return { fillUpType: "notFullUntil", fillUpDays: 31 };
  }

  return null;
}

class DeviceConnected extends Component<DeviceProps, DeviceState> {
  constructor(props: DeviceProps) {
    super(props);
    this.state = {
      showRoute: false,
      balesReadyEditOngoing: false,
    };
  }
  private getDeviceToolBar(): JSX.Element {
    const { t } = this.props;
    if (this.props.machineType === MachineType.Skip) {
      return (
        <Cell flexDirection="column">
          <IconTool
            src={icon_info}
            onClick={this.handleEditDevice}
            title={t(
              "deviceTable.tooltip.deviceDetails",
              "Show device details"
            )}
          />
          <ShowLocationsRect
            onClick={this.handleShowRoute}
            background={this.state.showRoute}
          >
            <IconShowLocation
              src={this.state.showRoute ? icon_route_active : icon_route}
            />
            {t("device.locations", "LOCATIONS")}
          </ShowLocationsRect>
        </Cell>
      );
    }
    return (
      <Cell>
        <IconTool
          src={icon_info}
          onClick={this.handleEditDevice}
          title={t("deviceTable.tooltip.deviceDetails", "Show device details")}
        />
        <IconTool
          marginLeft={5}
          src={icon_analytics}
          onClick={this.handleShowAnalytics}
          title={t("deviceTable.tooltip.showAnalytics", "Show analytics")}
        />
      </Cell>
    );
  }
  private handleShowRoute = (event: any): void => {
    event.stopPropagation();
    const toggle: boolean = !this.state.showRoute;
    this.setState({ showRoute: toggle });
    this.props.onShowRoute(this.props, toggle);
  };
  private handleEditDevice = (event: any): void => {
    event.stopPropagation();
    this.props.onEditDevice(this.props);
  };
  private handleChangeBalesReady = (newBalesReady: number) => {
    if (
      this.props.baleCounter &&
      this.props.baleCounter.pickupLimit <= newBalesReady
    ) {
      this.props.onResetBaleCounter(this.props);
    } else {
      this.props.onChangeBalesReady(this.props, newBalesReady);
    }
  };
  private handleResetBaleCounter = () => {
    this.props.onResetBaleCounter(this.props);
  };
  private handleShowAnalytics = (event: any): void => {
    event.stopPropagation();
    this.props.onShowAnalytics(this.props);
  };
  private getFillLevelPercentIcon(fillLevel: number | undefined): JSX.Element {
    if (this.props.machineType === MachineType.Skip) {
      return <> </>;
    }
    if (fillLevel === undefined || fillLevel === null || fillLevel < 0) {
      return (
        <FillLevelRect>
          <FillLevelPercentIcon width={0} background={"#28d754"}>
            <Percent>Unknown</Percent>
          </FillLevelPercentIcon>
        </FillLevelRect>
      );
    } else if (fillLevel >= 0 && fillLevel <= 25) {
      return (
        <FillLevelRect>
          <FillLevelPercentIcon width={fillLevel} background={"#BBF0C8"}>
            <Percent>{fillLevel}%</Percent>
          </FillLevelPercentIcon>
        </FillLevelRect>
      );
    } else if (fillLevel > 25 && fillLevel <= 75) {
      return (
        <FillLevelRect>
          <FillLevelPercentIcon width={fillLevel} background={"#75E691"}>
            <Percent>{fillLevel}%</Percent>
          </FillLevelPercentIcon>
        </FillLevelRect>
      );
    } else if (fillLevel > 75 && fillLevel < 100) {
      return (
        <FillLevelRect>
          <FillLevelPercentIcon width={fillLevel} background={"#72A5E3"}>
            <Percent>{fillLevel}%</Percent>
          </FillLevelPercentIcon>
        </FillLevelRect>
      );
    } else if (fillLevel >= 100 && fillLevel < 125) {
      return (
        <FillLevelRect>
          <FillLevelPercentIcon width={fillLevel} background={"#FFE000"}>
            <Percent>Full</Percent>
          </FillLevelPercentIcon>
        </FillLevelRect>
      );
    } else if (fillLevel >= 125) {
      return (
        <FillLevelRect>
          <FillLevelPercentIcon width={fillLevel} background={"#F51557"}>
            <Percent>Overfull</Percent>
          </FillLevelPercentIcon>
        </FillLevelRect>
      );
    }
    return (
      <FillLevelRect>
        <FillLevelPercentIcon width={0} background={"#28d754"}>
          <Percent>Unknown</Percent>
        </FillLevelPercentIcon>
      </FillLevelRect>
    );
  }
  private getFillLevelIcon(fillLevel: number | undefined): JSX.Element {
    if (this.props.machineType === MachineType.Skip) {
      return <> </>;
    }
    switch (fillLevel) {
      case FillState.Empty: {
        return (
          <FillLevelRect>
            <Circle>{FillState.Empty}</Circle>
          </FillLevelRect>
        );
      }
      case FillState.Level_1: {
        return (
          <FillLevelRect>
            <FillLevelIcon width={42} background={"#bbf0c8"}>
              <Circle>{FillState.Level_1}</Circle>
            </FillLevelIcon>
          </FillLevelRect>
        );
      }
      case FillState.Level_2: {
        return (
          <FillLevelRect>
            <FillLevelIcon width={60} background={"#28d754"}>
              <Circle>{FillState.Level_2}</Circle>
            </FillLevelIcon>
          </FillLevelRect>
        );
      }
      case FillState.AlmostFull: {
        return (
          <FillLevelRect>
            <FillLevelIcon width={88} background={"#13862e"}>
              <Circle>3</Circle>
            </FillLevelIcon>
          </FillLevelRect>
        );
      }
      case FillState.Full: {
        return (
          <FillLevelRect>
            <FillLevelIcon width={100} background={"#ffe000"}>
              <Circle>F</Circle>
            </FillLevelIcon>
          </FillLevelRect>
        );
      }
      case FillState.OverFull: {
        // Overfull
        return (
          <FillLevelRect>
            <FillLevelIcon width={100} background={"#f51557"}>
              <Circle>X</Circle>
            </FillLevelIcon>
          </FillLevelRect>
        );
      }
      default: {
        // Unknown
        return (
          <FillLevelRect>
            <FillLevelIcon width={100} background={"#ffffff"}>
              <Circle>?</Circle>
            </FillLevelIcon>
          </FillLevelRect>
        );
      }
    }
  }
  public shouldComponentUpdate(
    nextProps: DeviceProps,
    nextState: any
  ): boolean {
    if (!_.isEqual(nextProps.columns, this.props.columns)) {
      return true;
    }
    if (nextState.showRoute !== this.state.showRoute) {
      return true;
    }
    // Has devices state changed ?
    if (
      this.props.status === nextProps.status &&
      this.props.fillLevel === nextProps.fillLevel &&
      this.props.alias === nextProps.alias &&
      this.props.ownerGroup === nextProps.ownerGroup &&
      this.props.lastEmptyingDate === nextProps.lastEmptyingDate &&
      this.props.site === nextProps.site &&
      this.props.rentalAddress === nextProps.rentalAddress &&
      new Date(
        this.props.rentalStartDate ? this.props.rentalStartDate : ""
      ).getTime() ===
        new Date(
          nextProps.rentalStartDate ? nextProps.rentalStartDate : ""
        ).getTime() &&
      new Date(
        this.props.rentalEndDate ? this.props.rentalEndDate : ""
      ).getTime() ===
        new Date(
          nextProps.rentalEndDate ? nextProps.rentalEndDate : ""
        ).getTime() &&
      this.props.customerName === nextProps.customerName
    ) {
      return false;
    }
    return true;
  }
  private getColumnData(column: number): JSX.Element {
    const { t } = this.props;

    switch (column) {
      case SelectableColumns.STATUS: {
        const status = getPrimaryStatus(this.props);
        return (
          <Cell>
            <StatusIcon
              machineType={this.props.machineType}
              deviceStatus={status}
            />
          </Cell>
        );
      }
      case SelectableColumns.STATUS_MESSAGE: {
        const text = getStatusMessage(
          this.props,
          this.props.user.groups.includes("Europress")
        );
        return <Cell>{text}</Cell>;
      }
      case SelectableColumns.SERIAL_NUMBER: {
        return <Cell>{this.props.serialNumber}</Cell>;
      }
      case SelectableColumns.ASSET_NAME: {
        return <Cell>{this.props.alias}</Cell>;
      }
      case SelectableColumns.GROUP: {
        return <Cell>{this.props.ownerGroup}</Cell>;
      }
      case SelectableColumns.OWNER_GROUP_UPDATED_AT: {
        if (!this.props.ownerGroupUpdatedAt) {
          return <Cell />;
        }
        const date = new Date(this.props.ownerGroupUpdatedAt);
        return (
          <Cell>
            {format(date, "P", {
              locale: getDateFnsLocale(this.props.user.language),
            })}
          </Cell>
        );
      }
      case SelectableColumns.FILL_LEVEL: {
        const {
          scaleTotalWeightAfterLastEmptied,
          fillLevel,
          scaleTotalWeightEmptyingLimit,
          pressureCurveFillPercent,
          fillLevelMode,
        } = this.props;
        const showScaleWeight = scaleTotalWeightEmptyingLimit || false;
        const showAdminFillInformation =
          this.props.user.admin && pressureCurveFillPercent;
        const usePressureCurveFillPercent =
          pressureCurveFillPercent !== null && fillLevelMode === 1;
        const scaleWeightString = scaleTotalWeightAfterLastEmptied
          ? scaleTotalWeightAfterLastEmptied + "kg"
          : "0kg";
        return (
          <Cell flexDirection="column">
            {showAdminFillInformation && (
              <FillLevelPercent>
                {usePressureCurveFillPercent
                  ? fillLevel
                  : Math.round(pressureCurveFillPercent || 0) + "%"}
              </FillLevelPercent>
            )}
            {usePressureCurveFillPercent
              ? this.getFillLevelPercentIcon(
                  Math.round(pressureCurveFillPercent ?? -1)
                )
              : this.getFillLevelIcon(fillLevel)}
            {showScaleWeight && (
              <FillLevelPercent>{scaleWeightString}</FillLevelPercent>
            )}
          </Cell>
        );
      }
      case SelectableColumns.WASTE_FRACTION: {
        let wasteFraction = "";
        if (this.props.wasteFraction && this.props.wasteFraction !== "") {
          wasteFraction = this.props.wasteFraction;
        } else if (this.props.wasteFractionAlternative) {
          wasteFraction = this.props.wasteFractionAlternative;
        }
        return <Cell>{wasteFraction}</Cell>;
      }
      case SelectableColumns.PREDICTED_FILL_UP: {
        const predictedFillUp = getPredictedFillUp(this.props);

        let fillUpContent = "";
        if (predictedFillUp?.fillUpType === "full") {
          fillUpContent = t("device.predictedFillUpDays", {
            count: predictedFillUp?.fillUpDays,
            defaultValue: `{{count}} days`,
          });
        } else if (predictedFillUp?.fillUpType === "notFullUntil") {
          fillUpContent = t("device.notFullInOneMonth", "Over 30 days");
        }

        return <Cell>{fillUpContent}</Cell>;
      }
      case SelectableColumns.LAST_EMPTIED: {
        return (
          <Cell>
            {this.props.lastEmptyingDate
              ? new Date(this.props.lastEmptyingDate).toLocaleString()
              : undefined}
          </Cell>
        );
      }
      case SelectableColumns.SITE: {
        return <Cell>{this.props.site}</Cell>;
      }
      case SelectableColumns.ONLINE: {
        if (this.props.machineType === MachineType.Skip) {
          return <> </>;
        }
        return (
          <Cell>
            {this.props.onlineNow && this.props.onlineNow === true
              ? t("device.online", "Online")
              : t("device.offline", "Offline")}
          </Cell>
        );
      }
      case SelectableColumns.OPERATOR_GROUP: {
        return <Cell>{this.props.operatorGroup}</Cell>;
      }
      case SelectableColumns.OPERATOR_GROUP_UPDATED_AT: {
        if (!this.props.operatorGroupUpdatedAt) {
          return <Cell />;
        }
        const date = new Date(this.props.operatorGroupUpdatedAt);
        return (
          <Cell>
            {format(date, "P", {
              locale: getDateFnsLocale(this.props.user.language),
            })}
          </Cell>
        );
      }
      case SelectableColumns.EQUIPMENT_CODE: {
        return <Cell>{this.props.equipmentCode}</Cell>;
      }
      case SelectableColumns.LAST_ONLINE: {
        return (
          <Cell>
            {this.props.lastOnline
              ? new Date(this.props.lastOnline).toLocaleString()
              : undefined}
          </Cell>
        );
      }
      case SelectableColumns.SIGNAL_QUALITY_RSSI: {
        if (this.props.machineType === MachineType.Skip) {
          return <> </>;
        }
        return <Cell>{this.props.signalQualityRSSI}</Cell>;
      }
      case SelectableColumns.PHONE_NO: {
        return <Cell>{this.props.phoneNumber}</Cell>;
      }
      case SelectableColumns.SERVICE_IS_DUE: {
        if (this.props.machineType === MachineType.Skip) {
          return <> </>;
        }
        return (
          <Cell>
            {this.props.serviceIsDue && this.props.serviceIsDue === true
              ? t("device.serviceIsDue.yes", "YES")
              : t("device.serviceIsDue.no", "NO")}
          </Cell>
        );
      }
      case SelectableColumns.CONTROLLER: {
        if (this.props.machineType === MachineType.Skip) {
          return <> </>;
        }
        return (
          <Cell>
            {this.props.controllerTypeId
              ? "JE" + this.props.controllerTypeId
              : undefined}
          </Cell>
        );
      }
      case SelectableColumns.HW_REV: {
        if (this.props.machineType === MachineType.Skip) {
          return <> </>;
        }
        return <Cell>{this.props.controllerPcbHwRevision}</Cell>;
      }
      case SelectableColumns.F_W: {
        if (this.props.machineType === MachineType.Skip) {
          return <> </>;
        }
        return <Cell>{this.props.firmwareId}</Cell>;
      }
      case SelectableColumns.LOCATION: {
        const latLng = getLatLng(this.props);
        return (
          <Cell>
            {latLng !== null
              ? "(" + latLng.latitude + "," + latLng.longitude + ")"
              : undefined}
          </Cell>
        );
      }
      case SelectableColumns.PIC: {
        return <Cell></Cell>;
      }
      case SelectableColumns.CUSTOMER: {
        if (this.props.customerName) {
          return <Cell>{this.props.customerName}</Cell>;
        }
        return <Cell></Cell>;
      }
      case SelectableColumns.RENTAL_ADDRESS: {
        if (this.props.rentalAddress) {
          return <Cell>{this.props.rentalAddress}</Cell>;
        }
        return <Cell></Cell>;
      }
      case SelectableColumns.RENTAL_PERIOD: {
        return (
          <Cell>
            <RentalPeriod {...this.props} showText={false} />
          </Cell>
        );
      }
      case SelectableColumns.ADDRESS: {
        return <Cell>{this.props.address}</Cell>;
      }
      case SelectableColumns.BALE_COUNTER: {
        if (this.props.baleCounter) {
          return (
            <Cell>
              {this.state.balesReadyEditOngoing ? (
                <BalesReadyForm
                  balesReady={this.props.baleCounter.balesReady ?? 0}
                  pickupLimit={this.props.baleCounter.pickupLimit}
                  onSubmit={(newBalesReady) => {
                    this.handleChangeBalesReady(newBalesReady);
                    this.setState((state) => ({
                      ...state,
                      balesReadyEditOngoing: false,
                    }));
                  }}
                  onCancel={() =>
                    this.setState((state) => ({
                      ...state,
                      balesReadyEditOngoing: false,
                    }))
                  }
                />
              ) : (
                <BalesReadyCellContent
                  balesReady={this.props.baleCounter.balesReady ?? 0}
                  pickupLimit={this.props.baleCounter.pickupLimit}
                  onEdit={() =>
                    this.setState((state) => ({
                      ...state,
                      balesReadyEditOngoing: true,
                    }))
                  }
                  onReset={this.handleResetBaleCounter}
                />
              )}
            </Cell>
          );
        } else {
          return <Cell />;
        }
      }
      case SelectableColumns.BATTERY_LEVEL: {
        return <Cell>{this.props.batteryLevel}</Cell>;
      }
    }
    return <></>;
  }

  private getLocation(): number[] | undefined {
    if (this.props.latitude && this.props.longitude) {
      return [this.props.latitude, this.props.longitude];
    } else if (this.props.homeLatitude && this.props.homeLongitude) {
      return [this.props.homeLatitude, this.props.homeLongitude];
    } else {
      return undefined;
    }
  }

  private onDeviceRowClick = () => {
    const location = this.getLocation();
    if (location) {
      this.props.onDeviceRowClick(
        mapOptions.zoomInTarget,
        this.props.bounds,
        location
      );
    }
  };

  public render(): JSX.Element {
    return (
      <Row
        background={
          hasErrorOrWarning(this.props) ? "#ffebce" : "var(--ice-blue)"
        }
        clickable={this.getLocation() !== undefined}
        onClick={this.onDeviceRowClick}
      >
        <Column>{this.getDeviceToolBar()}</Column>
        {this.props.columns.map((column) => (
          <Column key={this.props.serialNumber + "-" + column}>
            {this.getColumnData(column)}
          </Column>
        ))}
      </Row>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    onDeviceRowClick: (zoom: number, bounds: Bounds, center: number[]) => {
      dispatch({
        type: TYPES.MAP_PARAMETERS_UPDATED,
        payload: {
          zoom: zoom,
          bounds: bounds,
          center: center,
        },
      });
    },
  };
};

const mapStateToProps = (state: any) => {
  return {
    bounds: state.map.bounds,
    user: _.pick(["groups", "admin", "language"], state.users),
  };
};

export const Device = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(DeviceConnected));
