import React, { useState } from "react";
import { FormTitle } from "../../../components/Common/FormTitle";
import { Formik } from "formik";
import { FormRectangle } from "../../../components/Common/FormRectangle";
import { FormWrapper } from "../../../components/Common/FormWrapper";
import { FormInput, FormLabel } from "../../../components/Common/FormInput";
import { GroupSelect } from "../GroupSelect";
import { FlexBoxWrapper } from "../../../components/Common";
import { FormSubmitButton } from "../../../components/Common/FormSubmitButton";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import * as TYPES from "../../../constants/actionTypes";
import { GroupType } from "../../../interfaces/types";
import { Toggle } from "../../../components/Common/Toggle";
import * as schemas from "./DevicesSchema";
import { groupHierarchiesSelector } from "../../../selectors/groups";

const ContentWrapper = styled.div`
  margin-top: 2rem;

  #serialNumbers {
    height: unset;
    padding: 0.7rem;
  }
`;

const ToggleSwitchWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 1.2rem;
  color: white;
`;

const SectionLabel = styled.div`
  margin-left: 12.5%;
  margin-bottom: 20px;
  color: white;
`;

const ToggleSwitchLabel = styled.span<{ active: boolean }>`
  // Highlight the selected option a little bit
  ${(props) =>
    props.active
      ? `
    text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.40);
  `
      : `
    font-weight: 400;
    color: #9c9c9c;
  `}
`;

const DeviceTabs = styled.div`
  display: flex;
  padding: 0 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid var(--seafoam-blue);
`;

const DeviceTab = styled.div<{ active: boolean }>`
  font-size: 12px;

  padding: 6px 12px;
  margin-right: 2px;

  position: relative;
  top: 1px;

  border: 1px solid var(--seafoam-blue);
  border-radius: 4px 4px 0 0;

  cursor: pointer;

  ${(props) =>
    props.active
      ? `
    color: white;
    background: #214269;
    border-bottom: 1px solid #214269;
  `
      : `
    color: #999999;
    background: #103050;
  `}
`;

enum DeviceTabType {
  UPDATE_GROUP = 0,
  UPDATE_ACTIVE_STATE = 1,
}

export function Devices() {
  const token = useSelector((state: any) => state.token.key);
  const groupOptions = useSelector(groupHierarchiesSelector);
  const isSubmitting = useSelector((state: any) => state.admin.isSubmitting);
  const dispatch = useDispatch();
  const [operatorUpdate, setOperatorUpdate] = useState(false);
  const [deviceActive, setDeviceActive] = useState(true);
  const [selectedTab, setSelectedTab] = useState(DeviceTabType.UPDATE_GROUP);

  const onSubmit = (values: any, actions: any) => {
    const serialNumbers = (values.serialNumbers as string)
      .split("\n")
      .filter(Boolean)
      .map((serial) => serial.trim());

    if (selectedTab === DeviceTabType.UPDATE_GROUP) {
      const dispatchType = operatorUpdate
        ? TYPES.UPDATE_OPERATOR_GROUP_FOR_DEVICES
        : TYPES.UPDATE_OWNER_GROUP_FOR_DEVICES;
      dispatch({
        type: dispatchType,
        payload: {
          data: {
            serialNumbers,
            groupId: values?.selectedGroupId,
          },
          token,
        },
      });
    } else if (selectedTab === DeviceTabType.UPDATE_ACTIVE_STATE) {
      dispatch({
        type: TYPES.UPDATE_ACTIVE_STATE_FOR_DEVICES,
        payload: {
          data: {
            serialNumbers,
            active: deviceActive,
          },
          token,
        },
      });
    }

    actions.setSubmitting(false);
  };

  const disableNothing = (_group: any) => {
    return false;
  };

  const disableNonOperatorGroup = (group: any) => {
    return group.groupType !== GroupType.OPERATOR;
  };

  const tabs = [
    {
      title: "Update group",
      type: DeviceTabType.UPDATE_GROUP,
    },
    {
      title: "Update active state",
      type: DeviceTabType.UPDATE_ACTIVE_STATE,
    },
  ];

  const TabNavigation = () => (
    <DeviceTabs>
      {tabs.map((tab: any) => {
        return (
          <DeviceTab
            key={tab.type}
            active={selectedTab === tab.type}
            onClick={() => setSelectedTab(tab.type)}
          >
            {tab.title}
          </DeviceTab>
        );
      })}
    </DeviceTabs>
  );

  return (
    <ContentWrapper>
      <FormRectangle>
        <FormTitle title={"Update device group and state"} />
        <Formik<{
          serialNumbers: string;
          selectedGroupId: undefined | number;
          active: boolean;
        }>
          enableReinitialize={true}
          initialValues={{
            serialNumbers: "",
            selectedGroupId: undefined,
            active: true,
          }}
          onSubmit={onSubmit}
          validationSchema={
            selectedTab === DeviceTabType.UPDATE_GROUP
              ? operatorUpdate
                ? schemas.OperatorDevicesSchema
                : schemas.OwnerDevicesSchema
              : schemas.DeviceActiveStateSchema
          }
        >
          {({
            errors,
            values,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
            setFieldTouched,
          }) => (
            <FormWrapper>
              <SectionLabel>Target devices</SectionLabel>
              <FormInput
                id="serialNumbers"
                type=""
                component="textarea"
                componentProps={{ rows: 5 }}
                label="Serial numbers (one per line)"
                error={
                  touched.serialNumbers && errors.serialNumbers
                    ? errors.serialNumbers
                    : ""
                }
                value={values.serialNumbers}
                onChange={handleChange}
                onBlur={handleBlur}
                required
              />
              <TabNavigation />
              {selectedTab === DeviceTabType.UPDATE_GROUP && (
                <>
                  <ToggleSwitchWrapper>
                    Owner group
                    <Toggle
                      selected={operatorUpdate}
                      onToggle={() => setOperatorUpdate(!operatorUpdate)}
                    />
                    Operator group
                  </ToggleSwitchWrapper>
                  <GroupSelect
                    id="selectedGroupId"
                    label={
                      operatorUpdate ? "New operator group" : "New owner group"
                    }
                    value={values.selectedGroupId}
                    onChange={setFieldValue}
                    error={
                      touched.selectedGroupId
                        ? errors.selectedGroupId
                        : undefined
                    }
                    options={groupOptions}
                    disabledGroup={
                      operatorUpdate ? disableNonOperatorGroup : disableNothing
                    }
                    // Only required if this tab is visible
                    required={
                      selectedTab === DeviceTabType.UPDATE_GROUP &&
                      !operatorUpdate
                    }
                    onBlur={setFieldTouched}
                  />
                </>
              )}
              {selectedTab === DeviceTabType.UPDATE_ACTIVE_STATE && (
                <>
                  <FormLabel>Device state</FormLabel>
                  <ToggleSwitchWrapper
                    style={{ marginTop: 40, marginBottom: 40 }}
                  >
                    <ToggleSwitchLabel active={!deviceActive}>
                      Passive
                    </ToggleSwitchLabel>
                    <Toggle
                      selected={deviceActive}
                      onToggle={() => setDeviceActive(!deviceActive)}
                    />
                    <ToggleSwitchLabel active={deviceActive}>
                      Active
                    </ToggleSwitchLabel>
                  </ToggleSwitchWrapper>
                </>
              )}
              <FlexBoxWrapper>
                <FormSubmitButton
                  type="submit"
                  disabled={isSubmitting}
                  cursor={isSubmitting ? "wait" : undefined}
                >
                  Save
                </FormSubmitButton>
              </FlexBoxWrapper>
            </FormWrapper>
          )}
        </Formik>
      </FormRectangle>
    </ContentWrapper>
  );
}
