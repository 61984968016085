import * as Yup from "yup";

const UsersSchema = Yup.object().shape({
  name: Yup.string().required("Email is required"),
  groupIds: Yup.array(Yup.number()).min(
    1,
    "User needs to belong to at least one group"
  ),
  primaryAnalyticsGroupId: Yup.string().required(
    "Analytics group needs to be selected"
  ),
  language: Yup.string(),
});

export default UsersSchema;
