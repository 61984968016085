import { useCallback, useEffect, useMemo, useState } from "react";
import * as React from "react";
import _ from "lodash/fp";
import { FixedSizeList } from "react-window";
import {
  Row,
  SortingRule,
  useExpanded,
  useFilters,
  useFlexLayout,
  usePagination,
  useResizeColumns,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import styled from "styled-components";
import { RoundDenimButton } from "./Button";
import { useScrollbarWidth } from "../../utils/scrollbar";

const CELL_FONT_SIZE_EM = 1.15;
const CELL_LINE_HEIGHT = 1.2;
const CELL_Y_PADDING_REM = 0.75;
const TABLE_FONT_SIZE_REM = 0.75;

const Controls = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;

  ${RoundDenimButton} ~${RoundDenimButton} {
    margin-left: 1rem;
  }
`;
const PageSelector = styled.select`
  cursor: pointer;
  appearance: none;
  background: none;
  border: none;
  font-size: 0.875rem;
  font-family: "Roboto";
  border: 1px solid #ccc;
  border-radius: 6px;
  text-align: center;
  padding: 2px;
  margin-right: 5px;
`;
const PageNumber = styled.span`
  font-size: 0.875rem;
  font-family: "Roboto";
  color: var(--col-214269);
`;
const StyledPaginationControls = styled.div`
  margin: 20px 0 0 0;
  display: flex;
  align-items: center;
  ${PageNumber} {
    margin: 4px 12px 0 24px;
  }
`;
const TableWrapper = styled.div<{ flexHeight?: boolean }>`
  ${(props) =>
    props.flexHeight &&
    `
    height: 0;
    flex-grow: 1;
  `}
  display: flex;
  flex-direction: column;
`;
const TextFilterInput = styled.input`
  width: 100%;
  padding: 0.3em 0.5em;
  border: 1px solid var(--ice-blue);
  border-radius: 1em;

  font-size: 0.9em;
  color: var(--col-214269);
  background-color: #ffffff;

  :focus {
    border-color: #007eff;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 0 3px rgba(0, 126, 255, 0.1);
    outline: none;
  }
`;
const StyledTable = styled.div<{ userTableStyles?: string }>`
  width: 100%;
  font-family: Roboto;
  font-size: ${TABLE_FONT_SIZE_REM}rem;
  background-color: white;
  border-collapse: collapse;
  overflow-x: auto;

  > .thead .th,
  .tbody .td {
    padding: ${CELL_Y_PADDING_REM}rem 1rem;
  }

  .tbody {
    width: 100%;
    display: inline-table;
  }

  > .thead .th {
    display: flex;
    flex-direction: column;
    align-items: center;
    // Ensure filter box is at the bottom of the .th container
    justify-content: space-between;

    > .filter {
      width: 100%;
    }

    border: 0;
    border-right: 1px solid white;
    :last-child {
      border-right: 0;
    }

    font-weight: bold;
    font-stretch: condensed;
    text-align: center;
    color: var(--1-c-2020);
    text-transform: uppercase;

    box-shadow: inset 0 -1px 0 0 var(--light-blue-grey),
      inset -1px 0 0 0 var(--ice-blue);

    &:hover {
      background-color: var(--ice-blue);
    }
  }

  > .thead .th .title {
    display: flex;
    align-items: center;
    flex-grow: 1;
  }

  > .thead .th:not(.sortable) .sortIcon {
    display: none;
  }

  > .thead .th.sortable .sortIcon {
    margin-left: 0.5rem;
    visibility: hidden;

    &.sorted {
      visibility: visible;
    }
  }

  > .thead .th.sortable:hover .sortIcon {
    visibility: visible;
    opacity: 0.6;

    &.sorted {
      opacity: 1;
    }
  }

  > .tbody .tr {
    box-shadow: inset 0 -1px 0 0 var(--light-blue-grey),
      inset -1px 0 0 0 #ffffff;
    background-color: var(--ice-blue-dark);

    &.even {
      background-color: var(--ice-blue);
    }

    &:hover {
      opacity: 0.8;
    }

    &.selected {
      background-color: var(--seafoam-blue-with-opacity);
    }
  }
  > .tbody .td {
    font-size: ${CELL_FONT_SIZE_EM}em;
    line-height: ${CELL_LINE_HEIGHT};
    border: 0;
    border-right: 1px solid white;

    :last-child {
      border-right: 0;
    }
  }
  // Ensure text in table rows is in one row when using infinite scrolling
  // because all rows need to have the same height
  > .tbody.infiniteScroll .td {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  ${(props) => props.userTableStyles}
`;
const NoDataText = styled.div`
  text-align: center;
  font-size: 1.4em;
  font-weight: 600;
  padding: 12px 0;
  color: #606060;
`;
interface PaginationControlsProps {
  nextPage: () => void;
  previousPage: () => void;
  canNextPage: boolean;
  canPreviousPage: boolean;
  gotoPage: any; // (pageIndex: number) => void;
  pageIndex: number;
  pageCount: number;
}
interface ManualPaginationControlsProps {
  nextPage?: () => void;
  previousPage?: () => void;
  canNextPage?: boolean;
  canPreviousPage?: boolean;
  gotoPage?: any; // (pageIndex: number) => void;
  pageIndex?: number;
  pageCount?: number;
}
function PaginationControls({
  nextPage,
  previousPage,
  canNextPage,
  canPreviousPage,
  gotoPage,
  pageIndex,
  pageCount,
}: PaginationControlsProps) {
  return (
    <StyledPaginationControls className="paginationControls">
      <Controls>
        <RoundDenimButton
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
        >
          {"«"}
        </RoundDenimButton>{" "}
        <RoundDenimButton onClick={previousPage} disabled={!canPreviousPage}>
          {"<"}
        </RoundDenimButton>{" "}
        <PageNumber>
          <PageSelector
            onChange={(e) => gotoPage(e.target.value)}
            value={pageIndex}
          >
            {[...Array(pageCount).keys()].map((index) => (
              <option value={index}>{index + 1}</option>
            ))}
          </PageSelector>
          / {pageCount}
        </PageNumber>
        <RoundDenimButton onClick={nextPage} disabled={!canNextPage}>
          {">"}
        </RoundDenimButton>
        <RoundDenimButton
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
        >
          {"»"}
        </RoundDenimButton>
      </Controls>
    </StyledPaginationControls>
  );
}

function DefaultColumnFilter({ column: { filterValue, setFilter } }: any) {
  return (
    <TextFilterInput
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      onClick={(e) => {
        e.stopPropagation();
      }}
      placeholder={`Search`}
    />
  );
}

const getStylesForSelectionColumn = (column: { id: string }) =>
  column.id === "formInternalSelection"
    ? {
        style: {
          width: 40,
          display: "flex",
          justifyContent: "center",
          paddingRight: 0,
          paddingLeft: 0,
        },
      }
    : {};

/**
 * Calculate row height for infinite scrolling from table element's font size.
 */
const getRowHeight = (tableElement: HTMLTableElement | null) => {
  const tableFontSize = tableElement
    ? parseFloat(
        window.getComputedStyle(tableElement).getPropertyValue("font-size")
      )
    : 12;
  const cellFontSize = tableFontSize * CELL_FONT_SIZE_EM;
  const remSize = tableFontSize / TABLE_FONT_SIZE_REM;

  return cellFontSize * CELL_LINE_HEIGHT + 2 * remSize * CELL_Y_PADDING_REM;
};

/**
 * The props passed to the table should be memoized with e.g. `useMemo` or defined
 * as constants to prevent expensive recalculations unless the data actually changes.
 *
 * See the documentation for react-table for further explanation.
 */
// TODO: Make the TableProps use the built-in ReactTable tableprops instead of redefining everything here
export interface TableProps {
  columns: any;
  data: any;
  getRowId?: (row: any) => any;
  // Provide props (e.g. style-related) to the row element
  getRowProps?: (row: any) => any;
  sortable?: boolean;
  onSelectionChange?: (rowIds: any[]) => any;
  // Add a "Select all" checkbox to the table header
  allowSelectAll?: boolean;
  // Enables row selection only for rows that pass this condition
  selectableCondition?: (row: any) => boolean;
  infiniteScroll?: boolean;
  infiniteScrollItemCount?: number;
  enableFilters?: boolean;
  paginate?: boolean;
  pageSize?: number;
  // If true, the parent component will handle pagination...
  manualPagination?: boolean;
  // ...and override the built-in props with given manualPaginationProps.
  manualPaginationProps?: ManualPaginationControlsProps;
  noDataText?: string;
  // Set to false to prevent table state, such as sorting or selections, from being reset when table
  // data is updated. This is automatically reset by the table component after updating the table,
  // so it needs to be set by the rendering component seperately before each change to table data.
  preventStateReset?: React.MutableRefObject<Boolean>;
  // Optional additional function to run when sorting is changed.
  onSortingChange?: (sortData: SortingRule<object>[]) => any;
  rowSubComponent?: any; //TODO: Type
  // Custom styles and overrides to add to the table's CSS.
  tableStyles?: any; // TODO: Type
  resizeable?: boolean;
  // Table should scale to take up as much height as is available in its parent flex container.
  flexHeight?: boolean;
  initialState?: any;
}
export function Table({
  columns,
  data,
  getRowId,
  onSelectionChange,
  allowSelectAll,
  selectableCondition,
  infiniteScroll,
  infiniteScrollItemCount = 10,
  enableFilters,
  paginate,
  pageSize,
  manualPagination,
  manualPaginationProps,
  preventStateReset,
  sortable,
  onSortingChange,
  noDataText,
  rowSubComponent,
  tableStyles,
  resizeable,
  flexHeight,
  initialState,
}: TableProps) {
  const [rowHeight, setRowHeight] = useState(35);
  const defaultColumn = useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const enableRowSelection = (() => {
    if (!onSelectionChange) return false; // Disable selection if no onSelectionChange function is provided
    if (!selectableCondition) return true; // Enable selection for all rows
    return selectableCondition; // Enable selection for rows that pass the condition
  })();

  const scrollbarWidth = useScrollbarWidth();
  const tableRef = useCallback(
    (tableNode) => setRowHeight(getRowHeight(tableNode)),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    prepareRow,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    gotoPage,
    pageOptions,
    state: { sortBy, selectedRowIds, pageIndex },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      getRowId,
      disableFilters: !enableFilters,
      disableSortBy: !sortable,
      autoResetFilters: !preventStateReset?.current,
      autoResetSelectedRows: !preventStateReset?.current,
      autoResetSortBy: !preventStateReset?.current,
      autoResetPage: !preventStateReset?.current,
      autoResetExpanded: !preventStateReset?.current,
      manualPagination: manualPagination,
      ...manualPaginationProps,
      initialState: {
        pageSize: pageSize || 10,
        ...initialState,
      },
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    useFlexLayout,
    useResizeColumns,
    (hooks) => {
      if (enableRowSelection) {
        hooks.visibleColumns.push((columns) => [
          {
            id: "formInternalSelection",
            Header: ({ getToggleAllRowsSelectedProps }) => {
              if (!allowSelectAll) return null;
              return (
                <div>
                  <input
                    type="checkbox"
                    {..._.omit("indeterminate", {
                      ...getToggleAllRowsSelectedProps(),
                    })}
                  />
                </div>
              );
            },
            Cell: ({ row }) => {
              if (selectableCondition && !selectableCondition(row)) {
                return null;
              }
              return (
                <div>
                  <input
                    type="checkbox"
                    {..._.omit("indeterminate", {
                      ...row.getToggleRowSelectedProps(),
                    })}
                  />
                </div>
              );
            },
          },
          ...columns,
        ]);
      }
    }
  );

  useEffect(() => {
    if (onSelectionChange !== undefined) {
      onSelectionChange(Object.keys(selectedRowIds));
    }
  }, [selectedRowIds, onSelectionChange]);
  // Make sure table state is reset unless otherwise specified by the rendering component
  useEffect(() => {
    if (preventStateReset !== undefined) {
      preventStateReset.current = false;
    }
  });

  useEffect(() => {
    if (typeof onSortingChange === "function") {
      onSortingChange(sortBy);
    }
  }, [onSortingChange, sortBy]);

  const RenderRow = useCallback(
    ({
      row,
      style,
      isEven,
    }: {
      row: Row<object>;
      isEven: boolean;
      style?: object;
    }) => {
      prepareRow(row);
      const userRowProps = row.getRowProps();
      const mergedStyle = userRowProps.style
        ? { ...userRowProps.style, ...style }
        : style;

      return (
        <div
          className={`tr ${isEven ? "even" : ""} ${
            Object.keys(selectedRowIds).includes(row.id) ? "selected" : ""
          }`}
          {...row.getRowProps({
            ...userRowProps,
            style: mergedStyle,
          })}
        >
          {row.cells.map((cell) => {
            return (
              <div
                className="td"
                title={cell.value}
                {...cell.getCellProps(getStylesForSelectionColumn(cell.column))}
              >
                {cell.render("Cell")}
              </div>
            );
          })}
          {row.isExpanded && rowSubComponent(row)}
        </div>
      );
    },
    [prepareRow, selectedRowIds, rowSubComponent]
  );

  const RenderInfiniteRow = useCallback(
    ({ index, style }) => (
      <RenderRow row={rows[index]} style={style} isEven={index % 2 === 0} />
    ),
    // eslint-disable-next-line
    [rows, RenderRow]
  );

  // Use built-in functions for pagination, override with manualPaginationProps if they are specified.
  const paginationControlProps: PaginationControlsProps = {
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    gotoPage,
    pageIndex,
    pageCount: pageOptions.length,
    ...manualPaginationProps,
  };

  return (
    <TableWrapper className="tableWrapper" flexHeight={flexHeight}>
      <StyledTable
        {...getTableProps()}
        ref={tableRef}
        userTableStyles={tableStyles}
      >
        <div className="thead">
          {headerGroups.map((headerGroup) => (
            <div
              className="tr"
              {...headerGroup.getHeaderGroupProps(
                infiniteScroll
                  ? {
                      style: {
                        width: `calc(100% - ${scrollbarWidth}px)`,
                      },
                    }
                  : {}
              )}
            >
              {headerGroup.headers.map((column) => (
                <div
                  className={`th ${sortable ? "sortable" : ""}`}
                  {...column.getHeaderProps([
                    getStylesForSelectionColumn(column),
                    column.getSortByToggleProps(),
                  ])}
                >
                  {resizeable && (
                    <div
                      {...column.getResizerProps()}
                      className={`resizer ${
                        column.isResizing ? "isResizing" : ""
                      }`}
                    />
                  )}
                  <div className="title">
                    <span>{column.render("Header")}</span>
                    {column.id !== "formInternalSelection" && (
                      <span
                        className={
                          column.isSorted ? "sortIcon sorted" : "sortIcon"
                        }
                      >
                        {column.disableSortBy
                          ? undefined
                          : column.isSortedDesc
                          ? "\u2191"
                          : "\u2193"}
                      </span>
                    )}
                  </div>
                  {column.canFilter && (
                    <div className="filter">{column.render("Filter")}</div>
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>
        <div
          className={`tbody ${infiniteScroll ? "infiniteScroll" : ""}`}
          {...getTableBodyProps()}
        >
          {rows.length === 0 && <NoDataText>{noDataText}</NoDataText>}
          {infiniteScroll ? (
            <FixedSizeList
              height={
                Math.min(infiniteScrollItemCount, rows.length) * rowHeight
              }
              itemCount={rows.length}
              itemSize={rowHeight}
              width={
                infiniteScrollItemCount < rows.length
                  ? "100%"
                  : `calc(100% - ${scrollbarWidth}px)`
              }
            >
              {RenderInfiniteRow}
            </FixedSizeList>
          ) : (
            (paginate ? page : rows).map((row, idx) => (
              <RenderRow row={row} isEven={idx % 2 === 0} key={row.id} />
            ))
          )}
        </div>
      </StyledTable>
      {paginate && <PaginationControls {...paginationControlProps} />}
    </TableWrapper>
  );
}

export type TableSettings = Omit<TableProps, "data">;
