import { useEffect } from "react";
import * as TYPES from "../../constants/actionTypes";

import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Link, RouteComponentProps } from "react-router-dom";
import { FormInput } from "../../components/Common/FormInput";
import { FormRectangle } from "../../components/Common/FormRectangle";
import { MultiGroupSelect } from "./GroupSelect";
import { FormBackground } from "../../components/Common/FormBackground";
import { FormSubmitButton } from "../../components/Common/FormSubmitButton";
import { FlexBoxWrapper } from "../../components/Common";
import { FormTitle } from "../../components/Common/FormTitle";
import UsersSchema from "./UsersSchema";
import { roleOptions } from "../../constants/options";
import { UserRole } from "../../interfaces/types";
import { FormWrapper } from "../../components/Common/FormWrapper";
import { groupHierarchiesSelector } from "../../selectors/groups";
import { tokenSelector } from "../../selectors/token";
import {
  adminLoadedSelector,
  adminSubmittingSelector,
  adminUserSelector,
} from "../../selectors/admin";
import {
  findGroupById,
  getChildGroupIds,
  getParentChain,
} from "../../utils/group";

type FormValues = {
  name: string;
  groupIds: number[];
  role: number;
  primaryAnalyticsGroupId: number | undefined;
  language: string;
};

type FormValuesInternal = Omit<
  FormValues,
  "role" | "primaryAnalyticsGroupId"
> & { role: string; primaryAnalyticsGroupId: string };

type UserFormProps = {
  values: FormValues;
  onSubmit: (values: FormValues) => void;
  isSubmitting: boolean;
  title: string;
  nameDisabled?: boolean;
};

const languageOptions = [
  {
    value: "en",
    text: "English",
  },
  {
    value: "fi",
    text: "Finnish",
  },
  {
    value: "pl",
    text: "Polish",
  },
];

function UserForm(props: UserFormProps) {
  const token = useSelector(tokenSelector);
  const groupHierarchies = useSelector(groupHierarchiesSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: TYPES.FETCH_GROUP_HIERARCHY,
      payload: { token },
    });
  }, [dispatch, token]);

  return (
    <FormBackground cursor={props.isSubmitting ? "wait" : undefined}>
      <FormRectangle>
        <FormTitle title={props.title} />
        <Formik<FormValuesInternal>
          initialValues={{
            ...props.values,
            role: props.values.role.toString(),
            primaryAnalyticsGroupId:
              props.values.primaryAnalyticsGroupId?.toString() ?? "",
          }}
          onSubmit={(values, _actions) => {
            if (props.isSubmitting) {
              return;
            }

            props.onSubmit({
              name: values.name.trim(),
              groupIds: values.groupIds,
              role: parseInt(values.role, 10),
              primaryAnalyticsGroupId: parseInt(
                values.primaryAnalyticsGroupId,
                10
              ),
              language: values.language,
            });
          }}
          validationSchema={UsersSchema}
        >
          {(formikProps) => {
            const {
              errors,
              values,
              touched,
              handleChange,
              handleBlur,
              setFieldValue,
              setFieldTouched,
            } = formikProps;

            /**
             * A user cannot belong to overlapping groups. For this reason, we prevent selecting
             * parent or child groups of an already-selected group.
             */
            const selectedGroupIds = new Set(
              values.groupIds.flatMap((rootGroupId) => [
                ...getChildGroupIds(groupHierarchies, rootGroupId),
                ...(getParentChain(groupHierarchies, rootGroupId)?.map(
                  (group) => group.groupId
                ) ?? []),
              ])
            );

            return (
              <FormWrapper>
                <FormInput
                  id="name"
                  type="text"
                  label="Email"
                  error={touched.name && errors.name ? errors.name : undefined}
                  value={values.name.trim()}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={props.nameDisabled}
                />
                <MultiGroupSelect
                  label="Groups"
                  id="groupIds"
                  values={values.groupIds}
                  onChange={(id, groupIdsUpdate) => {
                    const removedGroupIds = values.groupIds.filter(
                      (groupId) => !groupIdsUpdate.includes(groupId)
                    );
                    if (
                      removedGroupIds.includes(
                        parseInt(values.primaryAnalyticsGroupId, 10)
                      )
                    ) {
                      setFieldValue("primaryAnalyticsGroupId", "");
                    }
                    setFieldValue(id, groupIdsUpdate);
                  }}
                  onBlur={setFieldTouched}
                  error={
                    touched.groupIds &&
                    errors.groupIds &&
                    typeof errors.groupIds === "string"
                      ? errors.groupIds
                      : undefined
                  }
                  options={groupHierarchies}
                  disabledGroup={(group) => selectedGroupIds.has(group.groupId)}
                />
                <FormInput
                  id="primaryAnalyticsGroupId"
                  type=""
                  component="select"
                  label="Analytics group"
                  labelInfoTooltip="The group visible in data analytics until it supports multiple groups"
                  whiteInfoTooltip
                  error={
                    touched.primaryAnalyticsGroupId &&
                    errors.primaryAnalyticsGroupId
                      ? errors.primaryAnalyticsGroupId
                      : ""
                  }
                  value={values.primaryAnalyticsGroupId}
                  options={[
                    { value: "", text: "Select a group" },
                    ...values.groupIds.map((groupId) => ({
                      value: groupId.toString(),
                      text: findGroupById(groupHierarchies, groupId)?.name,
                    })),
                  ]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                />
                <FormInput
                  id="role"
                  type="select"
                  label="Role"
                  component="select"
                  options={roleOptions}
                  error={touched.role && errors.role ? errors.role : undefined}
                  value={values.role}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <FormInput
                  id="language"
                  type="select"
                  label="Language"
                  component="select"
                  options={languageOptions}
                  error={
                    touched.language && errors.language
                      ? errors.language
                      : undefined
                  }
                  value={values.language}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={props.nameDisabled}
                />
                <FlexBoxWrapper marginTop={20}>
                  <Link to={`/admin`}>
                    <FormSubmitButton
                      disabled={props.isSubmitting}
                      cursor={props.isSubmitting ? "wait" : undefined}
                    >
                      Cancel
                    </FormSubmitButton>
                  </Link>
                  &nbsp;
                  <FormSubmitButton
                    type="submit"
                    disabled={props.isSubmitting}
                    cursor={props.isSubmitting ? "wait" : undefined}
                  >
                    Submit
                  </FormSubmitButton>
                </FlexBoxWrapper>
              </FormWrapper>
            );
          }}
        </Formik>
      </FormRectangle>
    </FormBackground>
  );
}

export function CreateUserForm() {
  const dispatch = useDispatch();
  const token = useSelector(tokenSelector);
  const isSubmitting = useSelector(adminSubmittingSelector);

  return (
    <UserForm
      values={{
        groupIds: [],
        name: "",
        role: UserRole.NO_ROLE,
        primaryAnalyticsGroupId: undefined,
        language: "en",
      }}
      onSubmit={(values) => {
        dispatch({
          type: TYPES.CREATE_USER_FOR_ADMIN,
          payload: { data: values, token },
        });
      }}
      isSubmitting={isSubmitting}
      title="Create user"
    />
  );
}

export function UpdateUserForm(props: RouteComponentProps<{ id: string }>) {
  const dispatch = useDispatch();
  const user = useSelector(adminUserSelector);
  const token = useSelector(tokenSelector);
  const isLoaded = useSelector(adminLoadedSelector);
  const isSubmitting = useSelector(adminSubmittingSelector);

  const pathUserId = parseInt(props.match.params.id, 10);
  useEffect(() => {
    if (!isNaN(pathUserId)) {
      dispatch({
        type: TYPES.FETCH_USER_FOR_ADMIN,
        payload: { userId: pathUserId, token },
      });
    }
    // eslint-disable-next-line
  }, []);

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <div>Could not find user</div>;
  }

  return (
    <UserForm
      title="Edit user"
      values={{
        name: user.name,
        groupIds: user.groups.map((group) => group.id),
        role: user.role,
        primaryAnalyticsGroupId: user.primaryAnalyticsGroupId,
        language: user.language,
      }}
      onSubmit={(values) => {
        dispatch({
          type: TYPES.UPDATE_USER_FOR_ADMIN,
          payload: { userId: user.id, data: values, token },
        });
      }}
      isSubmitting={isSubmitting}
      nameDisabled
    />
  );
}
